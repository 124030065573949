import { SkipNavContent, SkipNavLink } from '@/components/tailwind/skip-nav'
import { Seo } from '@/components/tailwind/seo'
import { Header } from '@/components/tailwind/header'
import { CartSidebar } from '@/components/tailwind/cart-sidebar'
import { Footer } from '@/components/tailwind/footer'
import { useSiteConfig } from '@/hooks/use-site-config'
import { useScrollLock } from '@/hooks/use-scroll-lock'

import { FooterPaymentIcons } from '@/components/tailwind/footer-payment-icons'
import { Toast } from '@/components/tailwind/toast'

type LayoutTailwindDefault<T = any> = React.FC<T>

export const LayoutTailwindDefault: LayoutTailwindDefault = ({
  className,
  children,
  ...props
}) => {
  const siteConfig = useSiteConfig()
  const [isScrollLocked] = useScrollLock()

  return (
    <>
      <Seo />
      {/*<SkipNavLink />*/}

      <Header float={false} transparent={true} {...siteConfig} />
      <main
        className={className}
        //style={{
        //  overflowY: isScrollLocked ? 'hidden' : 'auto',
        //  overflowX: 'hidden',
        //}}
        {...props}
      >
        {children}
      </main>
      <Footer {...siteConfig}>
        <FooterPaymentIcons {...siteConfig} />
      </Footer>
      <Toast />
      <CartSidebar />
    </>
  )
}

export default LayoutTailwindDefault
