/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { classNames } from '@/utils/class-names'

export const Toast = ({ isVisible = true }) => {
  return (
    <Transition.Root show={isVisible} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter='ease-in-out duration-500'
        enterFrom='translate-y-full'
        enterTo='-translate-y-20'
        leave='ease-in-out duration-500'
        leaveFrom='-translate-y-20'
        leaveTo='translate-y-full'
      >
        <div
          id='toast-undo'
          className={classNames(
            'bottom flex items-center p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800'
          )}
          role='alert'
        >
          <div className='text-sm font-normal'>Conversation archived.</div>
          <div className='flex items-center ml-auto space-x-2'>
            <a
              className='text-sm font-medium text-blue-600 p-1.5 hover:bg-blue-100 rounded-lg dark:text-blue-500 dark:hover:bg-gray-700'
              href='#'
            >
              Undo
            </a>
            <button
              type='button'
              className='bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700'
              data-dismiss-target='#toast-undo'
              aria-label='Close'
            >
              <span className='sr-only'>Close</span>
              <svg
                aria-hidden='true'
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                />
              </svg>
            </button>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  )
}
