import React from 'react'
import { graphql } from 'gatsby'
import { LayoutTailwindDefault } from '@/layouts/layout-tailwind-default'
import { useProduct } from '@/hooks/use-product'
import { ProductSection } from '@/components/tailwind/product-section'

export const ShopifyProduct = ({ data: { product, staticImages } }: any) => {
  const { images } = useProduct(product)

  return (
    <LayoutTailwindDefault>
      <ProductSection product={product} />
    </LayoutTailwindDefault>
  )
}

export default ShopifyProduct

export const query = graphql`
  query($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      ...ProductSection
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`
